import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import linkedin from '../../images/social-media/linkedin.svg'
import MainGrid from '../styling/MainGrid'
import VerticalPadding from '../styling/VerticalPadding'
import Button from '../shared/Button/Button'
import { scrollTo } from '../../util/functions'

interface Member {
  img: string
  name: string
  role: string
  body: Array<string>
  social?: string
}
interface MeetProps {
  team: Array<Member>
}
const MeetOurTeamGrid: FunctionComponent<MeetProps> = ({ team }) => {
  const [showAllCards, toggleCards] = useState(false)
  const loadMore = () => {
    if (showAllCards) {
      setTimeout(() => {
        scrollTo('meet-our-team-grid')
      }, 300)
    }
    toggleCards(!showAllCards)
  }
  return (
    <Container>
      <MainGrid>
        <VerticalPadding>
          <Grid>
            {team &&
              team.map((item, key) => {
                return (
                  <Card key={key} hideMobile={key === 2}>
                    {item.img && <Img src={item.img} alt={item.name} />}
                    {item.name && <Name>{item.name}</Name>}
                    {item.role && (
                      <Role className="section-tag">{item.role}</Role>
                    )}
                    {item.body &&
                      item.body.map((item, key) => {
                        return <P key={key} dangerouslySetInnerHTML={{__html:item}}/>
                      })}
                    {item.social && (
                      <Linkedin href={item.social} target="_blank">
                        <img src={linkedin} alt="Linkedin logo" />
                      </Linkedin>
                    )}
                  </Card>
                )
              })}
          </Grid>
          <CardsWrapperMobile id={'meet-our-team-grid'}>
            {team &&
              team.map((item, key) => {
                if (key < 3 || showAllCards) {
                  return (
                    <Card key={key}>
                      {item.img && <Img src={item.img} alt={item.name} />}
                      {item.name && <Name>{item.name}</Name>}
                      {item.role && (
                        <Role className="section-tag">{item.role}</Role>
                      )}
                      {item.body &&
                        item.body.map((item, key) => {
                          return <P key={key} dangerouslySetInnerHTML={{__html:item}}/>
                        })}
                      {item.social && (
                        <Linkedin href={item.social} target="_blank">
                          <img src={linkedin} alt="Linkedin logo" />
                        </Linkedin>
                      )}
                    </Card>
                  )
                }
              })}
            {team.length > 3 && (
              <BtnContainer>
                <Button onClick={loadMore}>
                  {!showAllCards ? 'Show all team members' : 'Show less'}
                </Button>
              </BtnContainer>
            )}
          </CardsWrapperMobile>
        </VerticalPadding>
      </MainGrid>
    </Container>
  )
}

export default MeetOurTeamGrid

const Container = styled.section``

const Grid = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    margin: 30px 0 63px;
  }
`
interface CardProps {
  hideMobile?: boolean
}

const Card = styled.div<CardProps>`
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  padding: 27px 33px 66px;
  @media (min-width: 768px) {
    display: block;
  }
  @media (min-width: 1024px) {
    padding: 47px 40px 54px;
  }
  & a,
  & a:link,
  & a:visited {
    font-family: Roboto;
    font-weight: 300;
    text-decoration: none;
    color: #006822;
  }
  & a:hover,
  & a:active {
    text-decoration: underline;
  }
`

const Img = styled.img`
  width: 113px;
  height: 113px;
  margin-bottom: 26px;
`

const Name = styled.p`
  font-weight: bold;
  color: ${theme.text.main};
  margin-bottom: 6px;
`

const Role = styled.p`
  margin-bottom: 20px;
`

const P = styled.p`
  margin-bottom: 6px;
  @media (min-width: 768px) {
    margin-bottom: 9px;
  }
`

const Linkedin = styled.a`
  margin-top: 4px;
  background: ${theme.brand.blue};
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  & img {
    width: 15px;
    height: 15px;
  }

  &:not(:last-of-type) {
    margin-right: 6px;
  }

  &:hover {
    background: ${theme.text.main};
  }
  @media (min-width: 768px) {
    margin-top: 21px;
  }
`
const CardsWrapperMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  margin: 28px 0 40px;
  @media (min-width: 768px) {
    display: none;
  }
`

const BtnContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`
