import React from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb'

interface IBreadcrumb {
  title: string
  url: string
}

interface IGeneralHeader {
  breadcrumbData?: Array<IBreadcrumb>
  heading: string
}

const GeneralHeader = ({ breadcrumbData, heading }: IGeneralHeader) => {
  return (
    <Container>
      <MainGrid>
        <Breadcrumb data={breadcrumbData} />
        <TextWrapper>
          {heading && <MainHeading>{heading}</MainHeading>}
        </TextWrapper>
      </MainGrid>
    </Container>
  )
}

export default GeneralHeader

const Container = styled.header`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #e4efe1 1%, #eaefdd, #f2efd8);
  width: 100%;
`

const TextWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 67px 0;
  @media (min-width: 1024px) {
    padding: 64px 0 100px;
  }
`

const MainHeading = styled.h1``
