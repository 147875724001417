import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import GeneralHeader from '../components/Header/GeneralHeader'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import MeetOurTeamGrid from '../components/MeetOurTeam/MeetOurTeamGrid'
import { GetSortedMeetTheTeam } from '../components/MeetOurTeam/MeetOutTeamData'
// import { MeetTheTeamData } from '../components/MeetOurTeam/MeetOutTeamData'


const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Meet the team',
    url: '/meet-the-team',
  },
]

const MeetTheTeam = () => {
  return (
    <Layout
      activeLink="/meet-the-team"
      title="R&D Meet the team"
      description="R&D Meet the team"
    >
      <GeneralHeader breadcrumbData={breadcrumbData} heading="Meet the team" />

      <MeetOurTeamGrid
        team={GetSortedMeetTheTeam()}
      />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default MeetTheTeam
